import { Button, useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useContractWrite, useNetwork, useWaitForTransaction } from 'wagmi'
import { useStore } from 'stores/useStore'
import { utils } from 'ethers'
import contractAbi from 'abi/contractAbi.json'

const defaultReferrer = '0x0000000000000000000000000000000000000000'
const defaultAmount = '0.1'

type Props = {
  amount: string
  plan: string
  bg: string
}

export const InvestButton = ({ amount, plan, bg }: Props) => {
  const referrerParam = new URLSearchParams(window.location.search).get('r')
  const referrer = utils.isAddress(referrerParam || '') ? referrerParam : defaultReferrer
  const stakeAmount = isNaN(+amount) || amount === '' ? defaultAmount : amount
  const toast = useToast()
  const [{ data: networkData }] = useNetwork()
  const [{ data: contractWriteData, error: contractWriteError }, write] = useContractWrite(
    {
      addressOrName: process.env.REACT_APP_CONTRACT_ADDRESS,
      contractInterface: contractAbi,
    },
    'invest',
    {
      args: [referrer, plan, { value: utils.parseEther(stakeAmount) }],
    }
  )
  const [{ data: transactionData }] = useWaitForTransaction({
    wait: contractWriteData?.wait,
  })
  const { isLaunched, toggleInvestReload } = useStore()

  const Stake = () => {
    isNaN(+amount) || +amount <= 0
      ? toast({
          description: 'Please input a correct amount.',
          status: 'error',
          isClosable: true,
        })
      : write()
  }

  useEffect(() => {
    if (transactionData?.confirmations && transactionData.confirmations >= 1) {
      toast({
        description: 'AVAX staked.',
        status: 'success',
        isClosable: true,
      })

      toggleInvestReload()
    }
  }, [transactionData?.confirmations, toast, toggleInvestReload])

  useEffect(() => {
    contractWriteError &&
      toast({
        description: contractWriteError.message,
        status: 'error',
        isClosable: true,
      })
  }, [contractWriteError, toast])

  return (
    <Button
      onClick={Stake}
      isDisabled={true || !isLaunched || !networkData.chain || networkData.chain?.unsupported}
      bg={bg}
      color="white"
      fontWeight="medium"
      px="4"
      _hover={{
        transform: 'scale(1.05)',
      }}
      _active={{
        transform: 'scale(.95)',
        bg: bg,
      }}
    >
      Stake
    </Button>
  )
}
