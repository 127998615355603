import create from 'zustand'

type storeState = {
  investReload: number
  launchDate: number
  isLaunched: boolean
  toggleIsLaunched: () => void
  toggleInvestReload: () => void
}

export const useStore = create<storeState>((set) => ({
  investReload: 1,
  //launchDate: Date.now() + 5000,
  launchDate: 1648310400000,
  isLaunched: true,
  toggleIsLaunched: () => set(() => ({ isLaunched: true })),
  toggleInvestReload: () => set((state) => ({ investReload: state.investReload + 1 })),
}))
