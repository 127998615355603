import React from 'react'
import ReactDOM from 'react-dom'
import { ChakraProvider } from '@chakra-ui/react'
import { WagmiProvider, chain } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { providers } from 'ethers'
import theme from 'themes'
import App from 'App'
import 'focus-visible/dist/focus-visible'
import '@fontsource/barlow/400.css'
import '@fontsource/barlow/500.css'
import '@fontsource/barlow/600.css'
import '@fontsource/barlow/700.css'

// We only need to support one chain
const chains = [chain[process.env.REACT_APP_CHAIN]]

// Set up connectors
const connectors = () => {
  return [
    new InjectedConnector({
      chains,
      options: { shimDisconnect: true },
    }),
  ]
}

// https://github.com/tmm/wagmi/issues/189
const provider = () => {
  return new providers.StaticJsonRpcProvider(chains[0].rpcUrls[0])
}

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <WagmiProvider connectors={connectors} provider={provider} autoConnect>
        <App />
      </WagmiProvider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
