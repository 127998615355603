import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box, Container, Heading, Link } from '@chakra-ui/react'

export const Faq = () => {
  return (
    <Container maxW="xl" px={{ base: 4, '2xl': 0 }} pb="28">
      <Heading as="h3" size="xl" fontWeight="semibold" textAlign="center" mb="6">
        Frequently Asked Questions
      </Heading>
      <Accordion defaultIndex={[0]} allowToggle>
        <AccordionItem borderTop="none">
          <AccordionButton py="5">
            <Heading as="h4" size="sm" flex="1" fontWeight="semibold" textAlign="left">
              What's different between V1 and V2?
            </Heading>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel color="gray.600" pb={4}>
            V2 introduced several features on improving sustainability, including anti-whale protocols and reinvest. Read more about these in the{' '}
            <Link href="#" fontWeight="medium" isExternal={false}>
              whitepaper
            </Link>
            .
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton py="5">
            <Heading as="h4" size="sm" flex="1" fontWeight="semibold" textAlign="left">
              Project launch date?
            </Heading>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel color="gray.600" pb={4}>
            Mar 26, 16:00 UTC+0.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton py="5">
            <Heading as="h4" size="sm" flex="1" fontWeight="semibold" textAlign="left">
              Is the project audited?
            </Heading>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel color="gray.600" pb={4}>
            Yes, our contract has been audited by{' '}
            <Link href="#" fontWeight="medium" isExternal={false}>
              HazeCrypto
            </Link>
            , no vulnerabilities, no backdoors.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton py="5">
            <Heading as="h4" size="sm" flex="1" fontWeight="semibold" textAlign="left">
              Is this project safe to invest?
            </Heading>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel color="gray.600" pb={4}>
            <Box as="p" mb="3">
              Please understand that this isn't a guaranteed method of making money, and the team is not responsible for your losses, we have just created a platform that you can use.
            </Box>
            <Box as="p" mb="3">
              Your AVAX is deposited into the contract balance, and rewards are retrieved from the contract balance, which means if the contract balance falls to zero, your rewards cannot be retrieved.
            </Box>
            <Box as="p">This is a high risk investment, there is no guarantee that you will profit from your investment, so always do your own research and understand projects like this depend on other investors for repayment.</Box>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton py="5">
            <Heading as="h4" size="sm" flex="1" fontWeight="semibold" textAlign="left">
              How to calculate my total profit?
            </Heading>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel color="gray.600" pb={4}>
            <Box as="p" mb="3">
              Suppose you stake 10 AVAX into a 14 day plan, your daily rewards will be 10 * 15% = 1.5 AVAX, after 14 days your total rewards will be 21.
            </Box>
            <Box as="p" mb="3">
              As mentioned in the whitepaper there is a 7% tax on claim, so from that you will recieve 21 * 93% = 19.53 AVAX.
            </Box>
            <Box as="p">Nevertheless, if you wish to do another cycle, you can reinvest to avoid the 7% claim tax.</Box>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton py="5">
            <Heading as="h4" size="sm" flex="1" fontWeight="semibold" textAlign="left">
              Is there a locking period in your plans?
            </Heading>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel color="gray.600" pb={4}>
            Our plans don't have locking period, you can claim rewards every day if you so desire.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem borderBottom="none">
          <AccordionButton py="5">
            <Heading as="h4" size="sm" flex="1" fontWeight="semibold" textAlign="left">
              Do you have Telegram group or Discord?
            </Heading>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel color="gray.600" pb={4}>
            Yes, we have{' '}
            <Link href="#" fontWeight="medium" isExternal={false}>
              Discord
            </Link>
            .
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Container>
  )
}
