import {
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  InputGroup,
  InputLeftElement,
  NumberInput,
  NumberInputField,
  Select,
  Stack,
} from '@chakra-ui/react'
import { Avax } from 'icons'
import { SetStateAction, useState } from 'react'
import { useNetwork } from 'wagmi'
import { useUserInvest } from 'hooks/useUserInvest'
import { calcTimeDelta } from 'react-countdown'
import { InvestButton } from './InvestButton'

const plansInfo: Record<
  string,
  { days: string; dailyRewardRate: string; finalIncomeMultiplier: number; bg: string }
> = {
  0: {
    days: '14',
    dailyRewardRate: '15%',
    finalIncomeMultiplier: 2.1,
    bg: 'linear-gradient(to left top, rgb(31, 28, 44), rgb(146, 141, 171))',
  },
  1: {
    days: '21',
    dailyRewardRate: '13%',
    finalIncomeMultiplier: 2.73,
    bg: 'linear-gradient(to left top, rgb(162, 126, 47), rgb(207, 112, 90))',
  },
  2: {
    days: '28',
    dailyRewardRate: '11%',
    finalIncomeMultiplier: 3.08,
    bg: 'linear-gradient(to right, rgb(152, 102, 240), rgb(235, 77, 80))',
  },
}

const NewInvestCard = () => {
  const [plan, setPlan] = useState('0')
  const [amount, setAmount] = useState('0')
  const [{ data: networkData }] = useNetwork()

  const handlePlanChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPlan(event.target.value)
  }

  const handleAmountChange = (amount: SetStateAction<string>) => {
    setAmount(amount)
  }

  return (
    <GridItem
      colSpan={{ base: 6, md: 3 }}
      h="full"
      bg={plansInfo[plan].bg}
      p="1"
      borderRadius="2xl"
    >
      <Box h="full" bg="white" p="5" borderRadius="2xl">
        <Heading as="h3" size="md" fontWeight="semibold" mb="6">
          New Invest
        </Heading>

        <Flex align="center" justify="center" mb="6">
          <Flex
            align="center"
            justify="center"
            bg={plansInfo[plan].bg}
            h="28"
            w="28"
            borderRadius="full"
          >
            <Box textAlign="center">
              <Heading as="h3" color="white">
                {plansInfo[plan].days}
              </Heading>
              <Box as="span" color="white" mb="6">
                Days
              </Box>
            </Box>
          </Flex>
        </Flex>

        <Stack justify="space-between" direction="row" spacing="6" mb="6">
          <Box>
            <Heading as="h5" size="xs" color="gray.500" fontWeight="semibold" mb="0.5">
              Your Plan
            </Heading>
            <Flex align="center">
              <Box as="span" color="gray.900" fontWeight="semibold">
                <Select
                  onChange={handlePlanChange}
                  variant="unstyled"
                  w="18"
                  color="gray.900"
                  fontWeight="semibold"
                >
                  <option value="0">{plansInfo[0].days} Days</option>
                  <option value="1">{plansInfo[1].days} Days</option>
                  <option value="2">{plansInfo[2].days} Days</option>
                </Select>
              </Box>
            </Flex>
          </Box>

          <Stack direction="row" spacing="6">
            <Box>
              <Heading
                as="h5"
                size="xs"
                color="gray.500"
                fontWeight="semibold"
                textAlign="right"
                mb="0.5"
              >
                Daily Rewards
              </Heading>
              <Flex align="center" justify="flex-end">
                <Box as="span" color="gray.900" fontWeight="semibold">
                  {plansInfo[plan].dailyRewardRate}
                </Box>
              </Flex>
            </Box>

            <Box>
              <Heading
                as="h5"
                size="xs"
                color="gray.500"
                fontWeight="semibold"
                textAlign="right"
                mb="0.5"
              >
                Total Return
              </Heading>
              <Flex align="center" justify="flex-end">
                <Box as={Avax} h="4" w="4" mr="1" />
                <Box as="span" color="gray.900" fontWeight="semibold">
                  {isNaN(+amount) || +amount <= 0
                    ? '-'
                    : (+amount * plansInfo[plan].finalIncomeMultiplier).toFixed(2)}
                </Box>
              </Flex>
            </Box>
          </Stack>
        </Stack>

        <Flex align="center">
          <InputGroup flex="1 1 0">
            <InputLeftElement
              pointerEvents="none"
              p="2.5"
              children={<Box as={Avax} h="16" w="16" />}
            />
            <NumberInput onChange={handleAmountChange} min={0.1} w="full" mr="4" allowMouseWheel>
              <NumberInputField
                value={amount}
                disabled={!networkData.chain || networkData.chain?.unsupported}
                bg="gray.50"
                color="gray.700"
                fontSize="sm"
                fontWeight="medium"
                pl="10"
                border="none"
                maxLength={10}
                _placeholder={{ color: 'gray.900' }}
                placeholder="AVAX Amount"
              />
            </NumberInput>
          </InputGroup>

          <InvestButton amount={amount} plan={plan} bg={plansInfo[plan].bg} />
        </Flex>
      </Box>
    </GridItem>
  )
}

type PlanCardProps = {
  number: number
  plan: number
  amount: string
  start: string
  finish: string
}

const PlanCard = ({ number, plan, amount, start, finish }: PlanCardProps) => {
  const timeDelta = calcTimeDelta(+(finish + '000'))

  let countdownTime = 0
  let countdownUnit = ''

  if (timeDelta.completed) {
    countdownUnit = 'End'
  } else if (timeDelta.days > 1) {
    countdownTime = timeDelta.days
    countdownUnit = 'Days'
  } else if (timeDelta.days > 0) {
    countdownTime = timeDelta.days
    countdownUnit = 'Day'
  } else if (timeDelta.hours > 1) {
    countdownTime = timeDelta.hours
    countdownUnit = 'Hours'
  } else if (timeDelta.hours > 0) {
    countdownTime = timeDelta.hours
    countdownUnit = 'Hour'
  } else if (timeDelta.minutes > 1) {
    countdownTime = timeDelta.minutes
    countdownUnit = 'Mins'
  } else if (timeDelta.minutes > 0) {
    countdownTime = timeDelta.minutes
    countdownUnit = 'Min'
  }

  return (
    <GridItem
      colSpan={{ base: 6, md: 3 }}
      h="full"
      bg={plansInfo[plan].bg}
      p="1"
      borderRadius="2xl"
      opacity={timeDelta.completed ? '0.5' : 1}
    >
      <Box h="full" bg="white" p="5" borderRadius="2xl">
        <Heading as="h3" size="md" fontWeight="semibold" mb="6">
          Invest #{number}
        </Heading>

        <Flex align="center" justify="center" mb="6">
          <Flex
            align="center"
            justify="center"
            bg={plansInfo[plan].bg}
            h="28"
            w="28"
            borderRadius="full"
          >
            <Box textAlign="center">
              <Heading as="h3" color="white">
                {timeDelta.completed ? countdownUnit : countdownTime}
              </Heading>
              {!timeDelta.completed && (
                <Box as="span" color="white" mb="6">
                  {countdownUnit} left
                </Box>
              )}
            </Box>
          </Flex>
        </Flex>

        <Stack justify="space-between" direction="row" spacing="6" mb="6">
          <Box>
            <Heading as="h5" size="xs" color="gray.500" fontWeight="semibold" mb="0.5">
              Plan
            </Heading>
            <Flex align="center">
              <Box as="span" color="gray.900" fontWeight="semibold">
                {plansInfo[plan].days} Days
              </Box>
            </Flex>
          </Box>

          <Stack direction="row" spacing="6">
            <Box>
              <Heading
                as="h5"
                size="xs"
                color="gray.500"
                fontWeight="semibold"
                textAlign="right"
                mb="0.5"
              >
                Daily Rewards
              </Heading>
              <Flex align="center" justify="flex-end">
                <Box as="span" color="gray.900" fontWeight="semibold">
                  {plansInfo[plan].dailyRewardRate}
                </Box>
              </Flex>
            </Box>

            <Box>
              <Heading
                as="h5"
                size="xs"
                color="gray.500"
                fontWeight="semibold"
                textAlign="right"
                mb="0.5"
              >
                Total Return
              </Heading>
              <Flex align="center" justify="flex-end">
                <Box as={Avax} h="4" w="4" mr="1" />
                <Box as="span" color="gray.900" fontWeight="semibold">
                  {(+amount * plansInfo[plan].finalIncomeMultiplier).toFixed(2)}
                </Box>
              </Flex>
            </Box>
          </Stack>
        </Stack>

        <Stack justify="space-between" direction="row" spacing="6">
          <Box>
            <Heading as="h5" size="xs" color="gray.500" fontWeight="semibold" mb="0.5">
              Status
            </Heading>
            <Flex align="center">
              <Box as="span" color="gray.900" fontWeight="semibold">
                {timeDelta.completed ? 'Finished' : 'Active'}
              </Box>
            </Flex>
          </Box>

          <Box>
            <Heading
              as="h5"
              size="xs"
              color="gray.500"
              fontWeight="semibold"
              textAlign="right"
              mb="0.5"
            >
              Staked Amount
            </Heading>
            <Flex align="center" justify="flex-end">
              <Box as={Avax} h="4" w="4" mr="1" />
              <Box as="span" color="gray.900" fontWeight="semibold">
                {amount}
              </Box>
            </Flex>
          </Box>
        </Stack>

        <Flex align="center"></Flex>
      </Box>
    </GridItem>
  )
}

const PlanCards = () => {
  const investData = useUserInvest()

  return (
    <>
      {investData.map((item, key) => (
        <PlanCard
          key={key}
          number={item.number}
          plan={item.plan}
          amount={item.amount}
          start={item.start}
          finish={item.finish}
        />
      ))}
    </>
  )
}

export const Invest = () => {
  return (
    <Container maxW={{ base: 'lg', md: '5xl' }} px={{ base: '4', '2xl': '0' }} pb="28">
      <Grid templateColumns="repeat(6, 1fr)" gap={4}>
        <NewInvestCard />
        <PlanCards />
      </Grid>
    </Container>
  )
}
