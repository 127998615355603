import { Box, Button, useToast } from '@chakra-ui/react'
import { Metamask } from 'icons'
import { useEffect } from 'react'
import { useAccount, useConnect, useNetwork } from 'wagmi'

const Account = () => {
  const [{ data: accountData }, disconnect] = useAccount()

  return (
    <Button
      onClick={disconnect}
      bg="gray.50"
      color="gray.700"
      ml="2"
      px="4"
      _hover={{
        bg: 'brand.50',
        color: 'brand.400',
      }}
      _active={{
        bg: 'brand.50',
        transform: 'scale(.95)',
      }}
    >
      {accountData &&
        `${accountData.address.slice(0, 6)}...${accountData.address.slice(
          accountData.address.length - 4,
          accountData.address.length
        )}`}
    </Button>
  )
}

const WalletSelector = () => {
  const toast = useToast()
  const [{ data: connectData, error: connectError }, connect] = useConnect()

  useEffect(() => {
    connectError &&
      toast({
        description: connectError.message,
        status: 'error',
        isClosable: true,
      })
  }, [connectError, toast])

  return (
    <>
      {connectData.connectors.map((connector) => (
        <Button
          key={connector.id}
          onClick={() => connect(connector)}
          disabled={!connector.ready}
          bg="gray.50"
          color="gray.700"
          ml="2"
          px="4"
          _hover={{
            bg: 'brand.50',
            color: 'brand.400',
          }}
          _active={{
            bg: 'brand.50',
            transform: 'scale(.95)',
          }}
        >
          {(connector.name === 'MetaMask' || connector.name === 'Injected') && (
            <Box as={Metamask} h="6" mr="1" />
          )}
          {connector.name === 'Injected' ? 'MetaMask' : connector.name}
        </Button>
      ))}
    </>
  )
}

export const ConnectWallet = () => {
  const [{ data: accountData }] = useAccount()
  const [{ data: networkData }, switchNetwork] = useNetwork()

  return networkData.chain?.unsupported ? (
    <Button
      onClick={switchNetwork && (() => switchNetwork(networkData.chains[0].id))}
      bg="red.50"
      color="red.400"
      ml="2"
      px="4"
      _hover={{
        bg: 'red.100',
        color: 'red.500',
      }}
      _active={{
        bg: 'red.100',
        transform: 'scale(.95)',
      }}
    >
      Wrong Network
    </Button>
  ) : accountData ? (
    <Account />
  ) : (
    <WalletSelector />
  )
}
