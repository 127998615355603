import { Box, MenuItem, useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useContractWrite, useNetwork, useWaitForTransaction } from 'wagmi'
import { useStore } from 'stores/useStore'
import contractAbi from 'abi/contractAbi.json'

type Props = {
  plan: string
  days: string
  bg: string
}

export const ReinvestButton = ({ plan, days, bg }: Props) => {
  const toast = useToast()
  const [{ data: networkData }] = useNetwork()
  const [{ data: contractWriteData, error: contractWriteError }, write] = useContractWrite(
    {
      addressOrName: process.env.REACT_APP_CONTRACT_ADDRESS,
      contractInterface: contractAbi,
    },
    'reinvest',
    {
      args: [plan],
    }
  )
  const [{ data: transactionData }] = useWaitForTransaction({
    wait: contractWriteData?.wait,
  })
  const { isLaunched, toggleInvestReload } = useStore()

  const Stake = () => {
    write()
  }

  useEffect(() => {
    if (transactionData?.confirmations && transactionData.confirmations >= 1) {
      toast({
        description: 'AVAX staked.',
        status: 'success',
        isClosable: true,
      })

      toggleInvestReload()
    }
  }, [transactionData?.confirmations, toast, toggleInvestReload])

  useEffect(() => {
    contractWriteError &&
      contractWriteError.message === 'Internal JSON-RPC error.' &&
      toast({
        description: 'Invalid error, the minimum amount of reinvest is 0.1 AVAX.',
        status: 'error',
        isClosable: true,
      })
  }, [contractWriteError, toast])

  return (
    <MenuItem onClick={Stake} isDisabled={!isLaunched || !networkData.chain || networkData.chain?.unsupported}>
      <Box h="4" w="4" bg={bg} mr="2" borderRadius="full" />
      {days} Days
    </MenuItem>
  )
}
