import { Badge, Box, Container, Flex, Link, Stack } from '@chakra-ui/react'
import { Logo } from 'icons'
import { ReactNode } from 'react'
import { LaunchCountdown } from 'components/LaunchCountdown'
import { ConnectWallet } from 'components/ConnectWallet'

type NavLinkProps = {
  href: string
  children?: ReactNode
}

const NavLink = ({ href, children }: NavLinkProps) => {
  return (
    <Link
      href={href}
      isExternal={false}
      color="gray.900"
      fontWeight="medium"
      borderRadius="md"
      px="2.5"
      py="1.5"
      _hover={{
        bgColor: 'gray.50',
      }}
      _active={{
        transform: 'scale(.95)',
      }}
    >
      {children}
    </Link>
  )
}

export const Header = () => {
  return (
    <Box>
      <LaunchCountdown />
      <Container maxW="8xl" px={{ base: '4', '2xl': '0' }}>
        <Flex as="nav" justify="space-between" align="center" w="full" py="6">
          <Flex align="center" flex="1 1 0">
            <Box as={Logo} h="8" mr="2" />
            <Badge bg="linear-gradient(to right, rgb(152, 102, 240), rgb(235, 77, 80))" color="white">
              v2
            </Badge>
          </Flex>
          <Stack d={{ base: 'none', md: 'flex' }} direction="row" spacing="3">
            <NavLink href="#">Whitepaper</NavLink>
            <NavLink href="#">Audit</NavLink>
            <NavLink href="#">Contract</NavLink>
          </Stack>
          <Flex align="center" justify="flex-end" flex="1 1 0">
            <ConnectWallet />
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}
