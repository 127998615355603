import { Layout } from 'layouts/Layout'
import { Hero } from 'components/Hero'
import { Stats } from 'components/Stats'
import { Invest } from 'components/Invest'
import { Faq } from 'components/Faq'

function App() {
  return (
    <Layout>
      <Hero />
      <Stats />
      <Invest />
      <Faq />
    </Layout>
  )
}

export default App
