import { Button, useToast } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useContractWrite, useNetwork, useWaitForTransaction } from 'wagmi'
import { useStore } from 'stores/useStore'
import contractAbi from 'abi/contractAbi.json'

type Props = {
  claimable: string
}

export const ClaimButton = ({ claimable }: Props) => {
  const toast = useToast()
  const [{ data: networkData }] = useNetwork()
  const [{ data: contractWriteData, error: contractWriteError }, write] = useContractWrite(
    {
      addressOrName: process.env.REACT_APP_CONTRACT_ADDRESS,
      contractInterface: contractAbi,
    },
    'claim'
  )
  const [{ data: transactionData }] = useWaitForTransaction({
    wait: contractWriteData?.wait,
  })
  const { isLaunched } = useStore()

  const claimRewards = () => {
    isNaN(+claimable) || +claimable <= 0
      ? toast({
          description: 'You have 0 reward to claim.',
          status: 'error',
          isClosable: true,
        })
      : write()
  }

  useEffect(() => {
    transactionData?.confirmations &&
      transactionData.confirmations >= 1 &&
      toast({
        description: 'Rewards claimed.',
        status: 'success',
        isClosable: true,
      })
  }, [transactionData?.confirmations, toast])

  useEffect(() => {
    contractWriteError &&
      contractWriteError.message === 'Internal JSON-RPC error.' &&
      toast({
        description: 'Invalid error, you can claim only 1 time per 12 hours.',
        status: 'error',
        isClosable: true,
      })
  }, [contractWriteError, toast])

  return (
    <Button
      onClick={claimRewards}
      isDisabled={isNaN(+claimable) || !isLaunched || !networkData.chain || networkData.chain?.unsupported}
      bg="brand.100"
      color="brand.600"
      fontWeight="medium"
      px="4"
      _hover={{
        transform: 'scale(1.05)',
      }}
      _active={{
        transform: 'scale(.95)',
        bg: 'brand.500',
      }}
    >
      Claim
    </Button>
  )
}
