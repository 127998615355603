import { Box, Button, Container, Grid, GridItem, Heading, Flex, Stack, InputGroup, Input, InputRightElement, useClipboard, useToast, Menu, MenuButton, MenuList } from '@chakra-ui/react'
import { Avax } from 'icons'
import { useEffect, useState } from 'react'
import { useAccount, useNetwork } from 'wagmi'
import { useUserBalance } from 'hooks/useUserBalance'
import { useUserReferralStats } from 'hooks/useUserReferralStats'
import { useStore } from 'stores/useStore'
import { ReinvestButton } from './ReinvestButton'
import { ClaimButton } from './ClaimButton'
import { StatsSkeleton } from './StatsSkeleton'

const plansInfo: Record<string, { days: string; bg: string }> = {
  0: {
    days: '14',
    bg: 'linear-gradient(to left top, rgb(31, 28, 44), rgb(146, 141, 171))',
  },
  1: {
    days: '21',
    bg: 'linear-gradient(to left top, rgb(162, 126, 47), rgb(207, 112, 90))',
  },
  2: {
    days: '28',
    bg: 'linear-gradient(to right, rgb(152, 102, 240), rgb(235, 77, 80))',
  },
}

const BalanceCard = () => {
  const [currentDeposits, claimed, claimable] = useUserBalance()
  const [{ data: networkData }] = useNetwork()
  const { isLaunched } = useStore()

  return (
    <GridItem colSpan={{ base: 2, md: 1 }} h="full" bg="gray.50" p="6" borderRadius="2xl">
      <Heading as="h3" size="md" fontWeight="semibold" mb="6">
        Your Balance
      </Heading>

      <Stack justify="space-between" direction="row" spacing="6" mb="6">
        <Stack direction="row" spacing="6">
          <Box>
            <Heading as="h5" size="xs" color="gray.500" fontWeight="semibold" mb="0.5">
              Total Staking
            </Heading>
            <Flex align="center">
              <Box as={Avax} h="4" w="4" mr="1" />
              <Box as="span" color="gray.900" fontWeight="semibold">
                <StatsSkeleton w="8" value={currentDeposits} />
              </Box>
            </Flex>
          </Box>

          <Box d={{ base: 'none', md: 'block' }}>
            <Heading as="h5" size="xs" color="gray.500" fontWeight="semibold" mb="0.5">
              Total Claimed
            </Heading>
            <Flex align="center">
              <Box as={Avax} h="4" w="4" mr="1" />
              <Box as="span" color="gray.900" fontWeight="semibold">
                <StatsSkeleton w="16" value={claimed} />
              </Box>
            </Flex>
          </Box>
        </Stack>

        <Box>
          <Heading as="h5" size="xs" color="gray.500" fontWeight="semibold" textAlign="right" mb="0.5">
            Claimable
          </Heading>
          <Flex align="center" justify="flex-end">
            <Box as={Avax} h="4" w="4" mr="1" />
            <Box as="span" color="gray.900" fontWeight="semibold">
              <StatsSkeleton w="16" value={claimable} />
            </Box>
          </Flex>
        </Box>
      </Stack>

      <Box textAlign="right">
        <Menu>
          <MenuButton
            as={Button}
            isDisabled={true || isNaN(+claimable) || !isLaunched || !networkData.chain || networkData.chain?.unsupported}
            bg="brand.500"
            color="white"
            fontWeight="medium"
            mr="2"
            px="4"
            _hover={{
              transform: 'scale(1.05)',
            }}
            _active={{
              transform: 'scale(.95)',
              bg: 'brand.500',
            }}
          >
            Reinvest
          </MenuButton>
          <MenuList minW="10">
            <ReinvestButton plan="0" days={plansInfo[0].days} bg={plansInfo[0].bg} />
            <ReinvestButton plan="1" days={plansInfo[1].days} bg={plansInfo[1].bg} />
            <ReinvestButton plan="2" days={plansInfo[2].days} bg={plansInfo[2].bg} />
          </MenuList>
        </Menu>

        <ClaimButton claimable={claimable} />
      </Box>
    </GridItem>
  )
}

const ReferralCard = () => {
  const toast = useToast()
  const [refUrl, setRefUrl] = useState('')
  const { hasCopied, onCopy } = useClipboard(refUrl)
  const [{ data: accountData }] = useAccount()
  const [lv1, lv2, lv3, referred] = useUserReferralStats()

  useEffect(() => {
    accountData?.address ? setRefUrl(`https://${window.location.host}/?r=${accountData.address}`) : setRefUrl('Connect wallet to get your referral link')
  }, [accountData?.address])

  useEffect(() => {
    hasCopied &&
      toast({
        description: 'Referral link copied.',
        status: 'success',
        isClosable: true,
      })
  }, [hasCopied, toast])

  return (
    <GridItem colSpan={{ base: 2, md: 1 }} h="full" bg="papayawhip" p="6" borderRadius="2xl">
      <Heading as="h3" size="md" fontWeight="semibold" mb="6">
        Referral Stats
      </Heading>

      <Stack direction="row" justify="space-between" spacing="6" mb="6">
        <Box>
          <Heading as="h5" size="xs" color="gray.500" fontWeight="semibold" mb="0.5">
            Total Referred
          </Heading>
          <Flex align="center">
            <Box as={Avax} h="4" w="4" mr="1" />
            <Box as="span" color="gray.900" fontWeight="semibold">
              <StatsSkeleton w="16" value={referred} />
            </Box>
          </Flex>
        </Box>

        <Stack direction="row" spacing="6">
          <Box>
            <Heading as="h5" size="xs" color="gray.500" fontWeight="semibold" textAlign="right" mb="0.5">
              Level 1
            </Heading>
            <Flex align="center" justify="flex-end">
              <Box as="span" color="gray.900" fontWeight="semibold">
                <StatsSkeleton w="5" value={lv1} />
              </Box>
            </Flex>
          </Box>

          <Box>
            <Heading as="h5" size="xs" color="gray.500" fontWeight="semibold" textAlign="right" mb="0.5">
              Level 2
            </Heading>
            <Flex align="center" justify="flex-end">
              <Box as="span" color="gray.900" fontWeight="semibold">
                <StatsSkeleton w="5" value={lv2} />
              </Box>
            </Flex>
          </Box>

          <Box>
            <Heading as="h5" size="xs" color="gray.500" fontWeight="semibold" textAlign="right" mb="0.5">
              Level 3
            </Heading>
            <Flex align="center" justify="flex-end">
              <Box as="span" color="gray.900" fontWeight="semibold">
                <StatsSkeleton w="5" value={lv3} />
              </Box>
            </Flex>
          </Box>
        </Stack>
      </Stack>

      <InputGroup>
        <Input
          type="text"
          disabled={!accountData}
          defaultValue={refUrl}
          color="gray.900"
          borderColor="gray.500"
          fontSize="sm"
          fontWeight="medium"
          pr="16"
          onFocus={(e) => {
            e.target.select()
          }}
        />
        <InputRightElement width="16">
          <Button onClick={onCopy} disabled={!accountData} size="xs" borderRadius="md">
            Copy
          </Button>
        </InputRightElement>
      </InputGroup>
    </GridItem>
  )
}

export const Stats = () => {
  return (
    <Container maxW={{ base: 'lg', md: '5xl' }} px={{ base: '4', '2xl': '0' }} pb="12">
      <Grid templateColumns="repeat(2, 1fr)" gap={4}>
        <BalanceCard />

        <ReferralCard />
      </Grid>
    </Container>
  )
}
