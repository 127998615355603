import { Container, Flex, Link, Stack } from '@chakra-ui/react'
import { ReactNode } from 'react'

type NavLinkProps = {
  href: string
  children?: ReactNode
}

const NavLink = ({ href, children }: NavLinkProps) => {
  return (
    <Link
      href={href}
      isExternal={false}
      color="gray.900"
      fontSize="sm"
      _hover={{
        color: 'gray.700',
      }}
    >
      {children}
    </Link>
  )
}

export const Footer = () => {
  return (
    <Container maxW="8xl" px={{ base: '4', '2xl': '0' }}>
      <Flex as="nav" justify="space-between" align="center" w="full" py="6">
        <Flex align="center" fontSize="sm">
          © 2022 Avax Saving.
        </Flex>
        <Stack spacing="2" direction="row" textAlign="right">
          <NavLink href="#">Discord</NavLink>
          <NavLink href="#">Twitter</NavLink>
        </Stack>
      </Flex>
    </Container>
  )
}
