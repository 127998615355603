import { Skeleton } from '@chakra-ui/react'

type Props = {
  w: string
  value: string
}

export const StatsSkeleton = ({ w, value }: Props) => {
  return value === 'loading' ? <Skeleton h="4" w={w} my="1" /> : <>{value}</>
}
