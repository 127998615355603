import { useAccount, useContractRead } from 'wagmi'
import { utils } from 'ethers'
import contractAbi from 'abi/contractAbi.json'

let currentDeposits = '-'
let claimed = '-'
let claimable = '-'

export const useUserBalance = () => {
  const [{ data: accountData }] = useAccount()
  const [{ data: contractReadData }] = useContractRead(
    {
      addressOrName: process.env.REACT_APP_CONTRACT_ADDRESS,
      contractInterface: contractAbi,
    },
    'getUserBalance',
    {
      args: accountData?.address,
      watch: true,
    }
  )

  if (contractReadData) {
    currentDeposits = (+utils.formatEther(contractReadData[0].toString())).toFixed(2)
    claimed = (+utils.formatEther(contractReadData[1].toString())).toFixed(5)
    claimable = (+utils.formatEther(contractReadData[2].toString())).toFixed(5)
  }

  !accountData && (currentDeposits = claimed = claimable = '-')

  accountData && currentDeposits === '-' && (currentDeposits = claimed = claimable = 'loading')

  return [currentDeposits, claimed, claimable]
}
