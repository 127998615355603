import { ReactNode } from 'react'
import { Box } from '@chakra-ui/react'
import { Header } from 'layouts/Header'
import { Footer } from 'layouts/Footer'

type Props = {
  children?: ReactNode
}

export const Layout = ({ children }: Props) => {
  return (
    <Box>
      <Header />
      {children}
      <Footer />
    </Box>
  )
}
