import { useEffect } from 'react'
import { useContractRead } from 'wagmi'
import { useStore } from 'stores/useStore'
import { utils } from 'ethers'
import contractAbi from 'abi/contractAbi.json'

let contractBalance = '-'

export const useContractInfo = () => {
  const [{ data: contractReadData }, read] = useContractRead(
    {
      addressOrName: process.env.REACT_APP_CONTRACT_ADDRESS,
      contractInterface: contractAbi,
    },
    'getContractInfo',
    {}
  )
  const { investReload } = useStore()

  useEffect(() => {
    read()
  }, [investReload, read])

  if (contractReadData) {
    contractBalance = (+utils.formatEther(contractReadData[2].toString())).toFixed(2)
  }

  return [contractBalance]
}
