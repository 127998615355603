import { useEffect } from 'react'
import { useAccount, useContractRead } from 'wagmi'
import { utils } from 'ethers'
import contractAbi from 'abi/contractAbi.json'

let lv1 = '-'
let lv2 = '-'
let lv3 = '-'
let referred = '-'

export const useUserReferralStats = () => {
  const [{ data: accountData }] = useAccount()
  const [{ data: contractReadData }, read] = useContractRead(
    {
      addressOrName: process.env.REACT_APP_CONTRACT_ADDRESS,
      contractInterface: contractAbi,
    },
    'getUserReferralStats',
    {
      args: accountData?.address,
      skip: true,
    }
  )

  useEffect(() => {
    accountData?.address && read()
  }, [accountData?.address, read])

  if (contractReadData) {
    lv1 = contractReadData[0].toString()
    lv2 = contractReadData[1].toString()
    lv3 = contractReadData[2].toString()
    referred = (+utils.formatEther(contractReadData[3].toString())).toFixed(5)
  }

  !accountData && (lv1 = lv2 = lv3 = referred = '-')

  accountData && lv1 === '-' && (lv1 = lv2 = lv3 = referred = 'loading')

  return [lv1, lv2, lv3, referred]
}
