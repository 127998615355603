import { ComponentStyleConfig, extendTheme } from '@chakra-ui/react'

const Button: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: 'medium',
    borderRadius: 'lg',
    _disabled: {
      pointerEvents: 'none',
    },
  },
}

export default extendTheme({
  components: {
    Button,
  },
  styles: {
    global: {
      body: {
        //color: 'neutral.500',
      },
      a: {
        _hover: {
          textDecoration: 'none',
        },
      },
    },
  },
  fonts: {
    heading: 'Barlow',
    body: 'Barlow',
  },
  colors: {
    brand: {
      50: '#e4e9ff',
      100: '#b8c1fd',
      200: '#899df6',
      300: '#5c7aef',
      400: '#2f5bea',
      500: '#1535d0',
      600: '#0e1fa3',
      700: '#070e76',
      800: '#010349',
      900: '#03001e',
    },
    gray: {
      50: '#f2f2f3',
      100: '#d7d7d7',
      200: '#bdbdbd',
      300: '#a3a3a3',
      400: '#898989',
      500: '#6f6f6f',
      600: '#575757',
      700: '#3d3d3d',
      800: '#252525',
      900: '#0d0c0d',
    },
    /*
    brand: {
      100: '#FBFEDA',
      200: '#F7FDB5',
      300: '#F0FB90',
      400: '#E8F774',
      500: '#DDF247',
      600: '#BAD033',
      700: '#99AE23',
      800: '#798C16',
      900: '#62740D',
    },
    */
    neutral: {
      100: '#F3F3F3',
      200: '#E7E7E7',
      300: '#B7B7B7',
      400: '#6F6F6F',
      500: '#101010',
      600: '#0D0B0B',
      700: '#0B0808',
      800: '#090506',
      900: '#070304',
    },
    success: {
      100: '#F2FDDF',
      200: '#E1FBC1',
      300: '#C9F3A0',
      400: '#AFE786',
      500: '#89D860',
      600: '#66B949',
      700: '#479B36',
      800: '#2B7D25',
      900: '#16671A',
    },
    error: {
      100: '#FFEDD8',
      200: '#FFD6B3',
      300: '#FFBA8C',
      400: '#FF9E71',
      500: '#FF7144',
      600: '#E04F33',
      700: '#BD3325',
      800: '#981C19',
      900: '#7D0D10',
    },
  },
})
