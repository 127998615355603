import { useEffect } from 'react'
import { useAccount, useContractRead } from 'wagmi'
import { useStore } from 'stores/useStore'
import { utils } from 'ethers'
import contractAbi from 'abi/contractAbi.json'

export const useUserInvest = () => {
  const investData: { number: number; plan: any; amount: string; start: string; finish: string }[] =
    []
  const [{ data: accountData }] = useAccount()
  const [{ data: contractReadData }, read] = useContractRead(
    {
      addressOrName: process.env.REACT_APP_CONTRACT_ADDRESS,
      contractInterface: contractAbi,
    },
    'getUserAllDepositsInfo',
    {
      args: accountData?.address,
      skip: true,
    }
  )
  const { investReload } = useStore()

  useEffect(() => {
    accountData?.address && read()
  }, [accountData?.address, investReload, read])

  if (contractReadData) {
    contractReadData.map((item, key) =>
      investData.push({
        number: key + 1,
        plan: item.plan,
        amount: (+utils.formatEther(item.amount.toString())).toFixed(2),
        start: item.start.toString(),
        finish: item.finish.toString(),
      })
    )
  }

  !accountData && investData.splice(0, investData.length)

  return investData.reverse()
}
