import { Box, Container, Flex } from '@chakra-ui/react'
import { useStore } from 'stores/useStore'
import Countdown from 'react-countdown'

export const LaunchCountdown = () => {
  const { launchDate, isLaunched, toggleIsLaunched } = useStore()

  const handleComplete = () => {
    toggleIsLaunched()
  }

  return (
    <Box bg="linear-gradient(to right, rgb(152, 102, 240), rgb(235, 77, 80))">
      <Container maxW="8xl" px={{ base: 4, '2xl': 0 }} textAlign="center" py="3">
        <Flex d="flex" justify="center" color="white" fontWeight="medium">
          {isLaunched ? (
            <>Please DYOR, only invest the amount you can afford.</>
          ) : (
            <>
              <Box mr="2">We will be launching soon.</Box>
              <Box as={Countdown} date={launchDate} onComplete={handleComplete} bg="pink.800" px="2" borderRadius="md" />
            </>
          )}
        </Flex>
      </Container>
    </Box>
  )
}
