import { Alert, AlertIcon, Box, Container, Flex, Heading } from '@chakra-ui/react'
import { useContractInfo } from 'hooks/useContractInfo'
import { Avax } from 'icons'

export const Hero = () => {
  const [contractBalance] = useContractInfo()

  return (
    <Container maxW="8xl" px={{ base: 4, '2xl': 0 }} textAlign="center" py={{ base: 12, md: 16 }}>
      <Heading as="h1" size="3xl" color="gray.900" mb="2">
        Stake AVAX, Earn AVAX
      </Heading>
      <Heading as="h2" size="md" color="gray.600" fontWeight="normal" mb="8">
        The next generation of passive income, up to 210% return in 14 days.
      </Heading>

      <Flex d="inline-flex" align="center" justify="center" bg="brand.50" px="4" py="2" borderRadius="full">
        <Heading as="h5" size="xs" color="gray.500" fontWeight="semibold" mr="2">
          Contract Balance
        </Heading>
        <Flex align="center">
          <Box as={Avax} h="4" w="4" mr="1" />
          <Box as="span" color="gray.900" fontWeight="semibold">
            {contractBalance}
          </Box>
        </Flex>
      </Flex>

      <Flex justify="center" mt="6">
        <Alert status="error" maxW="xl" borderRadius="md">
          <AlertIcon />
          Our contract balance has fallen to zero, we have disabled the stake and reinvest button to protect you from investing any further funds.
        </Alert>
      </Flex>
    </Container>
  )
}
